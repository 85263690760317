import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import '../styles/blog-post.scss';
import Layout from "../components/layout";

export default function Template({ data }: any) {
    const { markdownRemark: post } = data;
    return (
        <>
            <Layout>
                <div className="blog-post-container">
                    <Helmet title={ `${ post.frontmatter.title } - Kickass Presbyterian` }/>
                    <div className="blog-post">
                        <h1>{ post.frontmatter.title }</h1>
                        <h4>{ post.frontmatter.date } - { post.frontmatter.readTime } read</h4>
                        <div className="blog-post-content" dangerouslySetInnerHTML={ { __html: post.html } }/>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                readTime
            }
        }
    }
`;
